import React, { useState, useEffect, useRef } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { db, getUserNicknamesFunction } from "../Firebase/firebasedb";
import {
  doc,
  setDoc,
  getDoc,
  orderBy,
  deleteDoc,
  serverTimestamp,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import {
  atypeOptionsCompat,
  equivalentRoleDict,
  isCampusLead,
  isHubLead,
  isPortalAdmin,
  isStudentOrMentor,
} from "../Fixed Sources/accountTypes";
import { format, parse, isValid, set } from "date-fns";
import { faker } from "@faker-js/faker";
import {
  AiTwotoneUnlock,
  AiOutlineUnlock,
  AiTwotoneLock,
  AiFillQuestionCircle,
  AiFillCloseCircle,
  AiOutlineInfoCircle,
  AiOutlineLock,
} from "react-icons/ai";
import { BsArrowCounterclockwise } from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import theme from "../Theme/theme";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useFormContext } from "react-hook-form";
import {
  loremIpsum,
  name,
  surname,
  fullname,
  username,
} from "react-lorem-ipsum";
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
  names,
  NumberDictionary,
} from "unique-names-generator";
import { cities, states, majors } from "./fake_sources";

export function makeid(l) {
  var text = "";
  var char_list =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < l; i++) {
    text += char_list.charAt(Math.floor(Math.random() * char_list.length));
  }
  return text;
}
export const processID = (rawID) => {
  if (rawID) return rawID.replace(/[^a-zA-Z0-9\- ]/g, "").replaceAll(" ", "-");
};
export const removeSpecialCharacters = (rawID) => {
  if (rawID) return rawID.replace(/[^a-zA-Z0-9\-\_\(\)\/ ',]/g, "");
};
export const containsSpecialCharacters = (rawID) => {
  if (rawID) return /[^a-zA-Z0-9\-\_\(\)\/ ',]/.test(rawID);
  return false;
};
export const allQuestionTypes = {
  section_header: ["Section Header", "Form Header"],
  plain_text: ["Plain Text", "Description"],
  short_answer: ["Short Answer", "What is your first name?"],
  long_answer: [
    "Long Answer",
    "Write a short description of your achievements (<50 words).",
  ],
  date: ["Date Input", "What is your birthday?"],
  email: ["Email Input", "What is your email address?"],
  phone_number: ["Phone Number Input", "What is your work phone number?"],
  multiple_choice: ["Multiple Choice", "What is your income range?"],
  checkbox: ["Checkboxes", "What is/are your race(s)?"],
  matrix: [
    "Matrix Style",
    "How satisfied were you with the following aspects on your last trip?",
  ],
  // 'conditional': ['Conditional', 'Your mailing address is different from shipping address'],
  dropdown: ["Dropdown", "What is current your university/institution?"],
  sign_date: ["Signature", "Please sign."],
  file_upload: ["File Upload", "Please upload a file (.pdf, .docx only)."],
};
export const countableQuestionTypes = {
  short_answer: ["Short Answer", "What is your first name?"],
  long_answer: [
    "Long Answer",
    "Write a short description of your achievements (<50 words).",
  ],
  date: ["Date Input", "What is your birthday?"],
  email: ["Email Input", "What is your email address?"],
  phone_number: ["Phone Number Input", "What is your work phone number?"],
  multiple_choice: ["Multiple Choice", "What is your income range?"],
  checkbox: ["Checkboxes", "What is/are your race(s)?"],
  matrix: [
    "Matrix Style",
    "How satisfied were you with the following aspects on your last trip?",
  ],
  conditional: [
    "Conditional",
    "Your mailing address is different from shipping address",
  ],
  dropdown: ["Dropdown", "What is current your university/institution?"],
  sign_date: ["Signature", "Please sign."],
  file_upload: ["File Upload", "Please upload a file (.pdf, .docx only)."],
};
export const isKeywordApplicable = (questionType) => {
  if (
    questionType == "short_answer" ||
    questionType == "long_answer" ||
    questionType == "date" ||
    questionType == "email" ||
    questionType == "phone_number" ||
    questionType == "file_upload"
  ) {
    return true;
  }
  return false;
};
export const RoleValidationComponent = ({
  requiredRoles,
  requiredInstitutions = null,
  redirect = false,
  setUserExternal,
  setUserDataExternal,
  setValidExternal,
}) => {
  //Handles Authentication and Redirection
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectDestination, setRedirectDestination] = useState(null);
  console.log(
    "requiredRoles",
    requiredRoles,
    "requiredInstitutions",
    requiredInstitutions
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
        if (setUserExternal) setUserExternal(user);
      } else {
        console.log("not logged in");
        setShouldRedirect(redirect);
        setRedirectDestination("/");
      }
    });
    return () => unsubscribe();
  }, [auth]);
  const navigate = useNavigate();
  useEffect(() => {
    if (redirect && shouldRedirect && redirectDestination) {
      navigate(redirectDestination);
    }
  }, [redirect, shouldRedirect, redirectDestination, navigate]);
  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      // console.log('user.uid', user.uid)
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          const { atype, institution } = data;
          setUserData(data);
          if (setUserDataExternal) setUserDataExternal(data);
          let pass1 = false;
          let pass2 = false;
          for (const requiredRole of requiredRoles) {
            console.log(requiredRole, atype);
            if (isPortalAdmin(requiredRole)) {
              if (isPortalAdmin(atype)) {
                pass1 = true;
                break;
              }
            } else if (isHubLead(requiredRole)) {
              if (isHubLead(atype)) {
                pass1 = true;
              }
            } else if (isCampusLead(requiredRole)) {
              if (isCampusLead(atype)) {
                pass1 = false;
                // setRedirectDestination("/");
              }
            } else {
              if (requiredRole != atype) {
                pass1 = false;
                // setRedirectDestination("/");
              }
            }
          }
          if (requiredInstitutions) {
            if (!requiredInstitutions.includes(institution)) {
              pass2 = true;
            }
          } else {
            pass2 = true;
          }

          const finalValid = pass1 && pass2;
          console.log(
            "initial pass1",
            pass1,
            "pass2",
            pass2,
            "finalValid",
            finalValid
          );
          if (setValidExternal) setValidExternal(finalValid); //pass = true => valid = true
          setShouldRedirect(!finalValid); //pass = false => redirect = true
          // console.log('shouldRedirect', shouldRedirect)
          setRedirectDestination("/");
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setRedirectDestination("/");
          setShouldRedirect(shouldRedirect);
        }
      });
    } else {
      // setRedirectDestination("/");
      // setShouldRedirect(redirect);
    }
  }, [user]);
  useEffect(() => {
    if (user) {
      if (userData) {
        const { atype, institution } = userData;
        let pass1 = false;
        let pass2 = false;
        for (const requiredRole of requiredRoles) {
          console.log(requiredRole, atype);
          if (isPortalAdmin(requiredRole)) {
            if (isPortalAdmin(atype)) {
              pass1 = true;
              break;
            }
          } else if (isHubLead(requiredRole)) {
            if (isHubLead(atype)) {
              pass1 = true;
              break;
            }
          } else if (isCampusLead(requiredRole)) {
            if (isCampusLead(atype)) {
              pass1 = true;
              break;
              // setRedirectDestination("/");
            }
          } else {
            if (requiredRole == atype) {
              pass1 = true;
              break;
              // setRedirectDestination("/");
            }
          }
        }
        console.log("requiredInstitutions", requiredInstitutions);

        if (requiredInstitutions) {
          if (requiredInstitutions.includes(institution)) {
            pass2 = true;
          }
        } else {
          pass2 = true;
        }

        const finalValid = pass1 && pass2;
        console.log("pass1", pass1, "pass2", pass2, "finalValid", finalValid);
        if (setValidExternal) setValidExternal(finalValid); //pass = true => valid = true
        setShouldRedirect(!finalValid); //pass = false => redirect = true
        // console.log('shouldRedirect', shouldRedirect)
        setRedirectDestination("/");
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setRedirectDestination("/");
        setShouldRedirect(shouldRedirect);
      }
    }
  }, [userData, user, requiredRoles, requiredInstitutions]);
  return (
    <div>
      {/* {userData && userData.atype == requiredRole ? <h2>Role permitted</h2> : <h2>Role failed</h2>} */}
    </div>
  );
};
export const HomePageURL = (userData) => {
  return "/";
};
const DateFormats = [
  "yyyy-MM-dd",
  "yyyy-M-d",
  "yyyy/M/d",
  "yyyy M d",
  "yyyy MM d",
  "yyyy/MM/d",
  "yyyy-MM-d",
  "yyyy MM dd",
  "yyyy/MM/dd",
  "yyyy-MM-dd",
  "yyyy M dd",
  "yyyy/M/dd",
  "yyyy-M-dd",
  "yyyy-MM-dd HH:mm:ss",
  "yyyy-MM-dd HH:mm:ss A",
  "yyyy-MM-dd HH:mm",
  "yyyy-MM-dd HH:mm A",
  "MMMM d yyyy",
  "M/d/yyyy",
  "M d yyyy",
  "MM d yyyy",
  "MM/d/yyyy",
  "MM-d-yyyy",
  "MMMM dd, yyyy",
  "MMMM dd, yyyy",
  "MMMM dd yyyy",
  "MMMM do, yyyy",
  "MMMM do yyyy",
  "MMM dd, yyyy",
  "MMM do, yyyy",
  "MMM d, yyyy",
  "MMM d yyyy",
  "MMMM dd yyyy",
  "MMMM do yyyy",
  "MMM dd yyyy",
  "MMM do yyyy",
  "EEEE, MMMM dd yyyy, HH:mm",
  "EEEE, MMMM dd yyyy, HH:mm A",
  "EEEE, MMMM do yyyy, HH:mm",
  // 'EEEE, MMMM do yyyy, HH:mm A',
  "EEEE MMMM dd yyyy HH:mm",
  "EEEE MMMM dd yyyy HH:mm A",
  // 'EEEE MMMM do yyyy HH:mm',
  // 'EEEE MMMM do yyyy HH:mm A',
  "EEEE, MMMM dd yyyy, HH:mm:ss",
  "EEEE, MMMM dd yyyy, HH:mm:ss A",
  // 'EEEE, MMMM do yyyy, HH:mm:ss',
  // 'EEEE, MMMM do yyyy, HH:mm:ss A',
  "EEEE MMMM dd yyyy HH:mm:ss",
  "EEEE MMMM dd yyyy HH:mm:ss A",
  // 'EEEE MMMM do yyyy HH:mm:ss',
  // 'EEEE MMMM do yyyy HH:mm:ss A',
  "MM/dd/yyyy",
  "MM dd yyyy",
  "dd MMMM yyyy",
  "do MMMM yyyy",
  "yyyy MMMM dd",
  "yyyy-MM-dd HH:mm:ss",
  "EEE MMM dd yyyy",
];
export const parseMultiple = (dateString, formatString = DateFormats) => {
  let result;
  for (let i = 0; i < formatString.length; i++) {
    result = parse(dateString, formatString[i], new Date());
    if (isValid(result)) {
      const formattedTxt = format(result, "yyyy-MM-dd");
      return formattedTxt;
    }
  }
  return null;
};
export const parseMultiple2 = (dateString, formatString = DateFormats) => {
  let result;
  for (let i = 0; i < formatString.length; i++) {
    result = parse(dateString, formatString[i], new Date());
    if (isValid(result)) {
      const formattedTxt = format(result, "MMMM dd, yyyy");
      return formattedTxt;
    }
  }
  return null;
};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export function generateRandomNicknames(rhyme = false) {
  let nickname = "";
  if (rhyme) {
    let fName = faker.name.firstName();
    let adjective = faker.word.adjective();
    while (fName[0].toLowerCase() != adjective[0].toLowerCase()) {
      adjective = faker.word.adjective();
    }
    nickname = `${toTitleCase(adjective)} ${fName}`;
  } else {
    let fName = faker.name.firstName();
    let adjective = faker.word.adjective();
    nickname = `${toTitleCase(adjective)} ${fName}`;
  }
  return nickname;
}
export const FakeNameDropDown = ({ userData, errors, setValue }) => {
  const numberOfNames = 5;
  let initList = [];
  let initName = "";
  if (
    userData &&
    isStudentOrMentor(userData.atype) &&
    userData.nickname &&
    userData.nickname != ""
  ) {
    initList.push(userData.nickname);
    initName = userData.nickname;
  }

  const [nicknameList, setNicknameList] = useState([...initList]);
  const [takenNames, setTakenNames] = useState([]);
  const [refreshClick, setRefreshClick] = useState(0);

  // console.log('atype', atype)

  useEffect(() => {
    const fetchNicknames = async () => {
      try {
        console.log("fetching nicknames");
        const result = await getUserNicknamesFunction();
        console.log("result", result);
        const data = result.data;
        const allNicknamesServer = data.nicknames; // Assuming the backend returns an object with a nicknames array

        // Generate random nicknames that are not in the list of existing nicknames
        const newNicknameList = [...Array(numberOfNames)].map(() => {
          let taken = true;
          let nickname = "";
          while (taken) {
            nickname = generateRandomNicknames(false); // Use your random nickname generation logic here
            if (!allNicknamesServer.includes(nickname)) {
              taken = false;
            }
          }
          return nickname;
        });

        const newNicknameListRhyme = [...Array(numberOfNames)].map(() => {
          let taken = true;
          let nickname = "";
          while (taken) {
            nickname = generateRandomNicknames(true); // Use your random nickname generation logic with rhyme option
            if (!allNicknamesServer.includes(nickname)) {
              taken = false;
            }
          }
          return nickname;
        });

        setNicknameList([
          ...initList,
          ...newNicknameListRhyme,
          ...newNicknameList,
        ]);
      } catch (error) {
        console.error("Error fetching nicknames:", error);
      }
    };

    fetchNicknames();
  }, [refreshClick]);
  // console.log('errors', errors)
  // const { handleSubmit, reset, register, setValue, formState: { errors } } = methods;
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value ||
                child.props.children.toLowerCase().startsWith(value) ||
                child.props.children.startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      variant={
        errors["nickname"] && !currentEventKey
          ? "outline-danger"
          : "outline-theme"
      }
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </Button>
  ));
  const [currentEventKey, setCurrentEventKey] = useState(initName);
  return (
    <div>
      <ReactTooltip backgroundColor={theme.highlightColor} />

      <Dropdown
        className="col-md-6 mt-2 "
        onSelect={(eventKey, event) => {
          console.log("setValue", setValue);
          setValue("nickname", eventKey);
          setCurrentEventKey(eventKey);
        }}
      >
        <Dropdown.Toggle
          id="dropdown-custom-components"
          variant="outline-theme"
        >
          Select Your Nickname: <b>{currentEventKey}</b>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div style={{ textAlign: "center" }}>
            <Button
              className="mb-2"
              variant="outline-theme"
              size="sm"
              onClick={() => {
                setRefreshClick(refreshClick + 1);
              }}
            >
              <BsArrowCounterclockwise
                style={{
                  marginRight: "3px",
                  color: theme.highlightColor,
                  fontSize: "16px",
                }}
              />
              Refresh Names
            </Button>
          </div>
          {nicknameList.map((nName) => {
            return (
              <Dropdown.Item eventKey={nName} active={nName == currentEventKey}>
                {nName}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
        <AiOutlineLock
          style={{
            marginLeft: "2px",
            marginBottom: "3px",
            color: "green",
            fontSize: "17px",
          }}
          data-tip={`This name will be shown to other users instead.`}
        />
      </Dropdown>

      <div className="is-invalid">
        {/* <div className="invalid-feedback">hello22</div> */}
      </div>
      <div className="invalid-feedback mb-2">
        {errors["nickname"] && !currentEventKey
          ? errors["nickname"].message
          : ""}
      </div>
    </div>
  );
};
export const encryptableQuestionTypes = [
  "short_answer",
  "long_answer",
  "email",
  "phone_number",
];
export const requirableQuestionTypes = [
  "short_answer",
  "long_answer",
  "date",
  "email",
  "phone_number",
  "file_upload",
  "dropdown",
];
export const FormLibrary = ({
  setFormLibraryExternal = null,
  accountType = null,
  publishedOnly = true,
}) => {
  const [formLibrary, setFormLibrary] = useState([]);
  useEffect(() => {
    const formLibraryRef = collection(db, "form_library");
    const q = query(formLibraryRef);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const formLibraryServer = [];
      let foundFormToEdit = false;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // console.log('data', data)
        // console.log('Utils', accountType, data.allowedRoles)
        if (publishedOnly) {
          if (data.status == "published") {
            if (
              accountType &&
              data.allowedRoles &&
              data.allowedRoles.includes(accountType)
            ) {
              formLibraryServer.push(data);
            } else {
              if (accountType) {
                // console.log('accountType', accountType)
                if (
                  isCampusLead(accountType) ||
                  isHubLead(accountType) ||
                  isPortalAdmin(accountType)
                ) {
                  // console.log('qualified')
                  formLibraryServer.push(data);
                }
              }
            }
          }
        } else {
          if (
            accountType &&
            data.allowedRoles &&
            data.allowedRoles.includes(accountType)
          ) {
            formLibraryServer.push(data);
          } else {
            if (accountType) {
              // console.log('accountType', accountType)
              if (
                isCampusLead(accountType) ||
                isHubLead(accountType) ||
                isPortalAdmin(accountType)
              ) {
                // console.log('qualified')

                formLibraryServer.push(data);
              }
            }
          }
        }
      });
      if (setFormLibraryExternal) {
        setFormLibraryExternal([...formLibraryServer]);
      }
      setFormLibrary([...formLibraryServer]);
    });
  }, []);
  return <></>;
};
export const formatDate = (date) => {
  if (date) {
    const createdDate = date.toDate().toDateString();
    const createdTime = date.toDate().toLocaleTimeString("en-US");
    return `${createdDate}, ${createdTime}`;
  }
  return "N/A";
};
export function convertToDate(dateString) {
  if (dateString === null || dateString.trim() === "") {
    return null;
  }

  const parts = dateString.split("-");
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1; // Months are zero-based
  const day = parseInt(parts[2]);

  const date = new Date(year, month, day);
  return date;
}
export function convertTimestampToDate(timestampString) {
  const matches = timestampString.match(/seconds=(\d+), nanoseconds=(\d+)/);

  if (matches) {
    const seconds = parseInt(matches[1]);
    const nanoseconds = parseInt(matches[2]);

    const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
    const date = new Date();
    date.setTime(milliseconds);

    return date;
  } else {
    throw new Error("Invalid timestamp format");
  }
}

// Example usage:
const timestampString = "Timestamp(seconds=1663998349, nanoseconds=927000000)";
try {
  const date = convertTimestampToDate(timestampString);
  console.log(date);
} catch (error) {
  console.error(error.message);
}
export function getDifferenceInDays(date1, date2) {
  if (!date1) {
    date1 = new Date();
  }
  if (!date2) {
    date2 = new Date();
  }

  // To calculate the time difference of two dates
  var Difference_In_Time = date2.getTime() - date1.getTime();
  // console.log('difference', Difference_In_Time)
  // To calculate the no. of days between two dates
  var Difference_In_Days = Math.abs(
    Math.round(Difference_In_Time / (1000 * 3600 * 24))
  );
  // console.log('difference days', Difference_In_Days)

  return Difference_In_Days;
}
export const deleteFormsPermanent = async (formID, callback) => {
  if (formID) {
    await deleteDoc(doc(db, "form_library_recently_deleted", formID));
    // alert(`Form ${formID} removed permanently from database`);
    if (callback) {
      console.log("callback deleteFormsPermanent", callback);
      callback();
    }
  }
};
export const deleteChartPermanent = async (chartID, callback) => {
  if (chartID) {
    await deleteDoc(doc(db, "chart_library", chartID));
    // alert(`Form ${formID} removed permanently from database`);
    if (callback) {
      console.log("callback deleteChartPermanent", callback);
      callback();
    }
  }
};
export const restoreForm = async (formID, userData = null, callback) => {
  if (formID) {
    let docSnapshot = await getDoc(
      doc(db, "form_library_recently_deleted", formID)
    );
    let formData = {};
    if (docSnapshot.data()) {
      formData = docSnapshot.data();
      console.log(`Form ${formID} is now moved to main dashboard!`);
      if (userData) {
        formData = {
          ...formData,
          status: "unpublished",
          restoredAt: serverTimestamp(),
          restoredBy: userData.userID,
          restoreEmail: userData.email,
        };
      } else {
        formData = {
          ...formData,
          status: "unpublished",
          restoredAt: serverTimestamp(),
        };
      }
      console.log("restored formData", formData);

      await setDoc(doc(db, "form_library", formID), formData, { merge: true });
      await deleteDoc(doc(db, "form_library_recently_deleted", formID));
    }

    callback();
  }
};

export const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2"
          style={{ width: 400 }}
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              (child.props.children != undefined &&
                (child.props.children.toUpperCase().includes(value) ||
                  child.props.children.toLowerCase().includes(value)))
          )}
        </ul>
      </div>
    );
  }
);
export const randomDate = (start = null, end = null) => {
  if (!start) {
    start = new Date(2020, 1, 1);
  }
  if (!end) {
    end = new Date();
  }
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};
export const notQuestionTypes = ["plain_text", "form_title", "section_header"];
const generateRandomAnswersSilent = (formID, formQuestions) => {
  let randomAnswers = { formID: formID };
  // console.log(formQuestions)
  formQuestions.forEach((q) => {
    let randomAnswer = "";

    if (q.question_type == "short_answer") {
      if (q.questionID.toLowerCase().includes("name")) {
        // randomAnswer = uniqueNamesGenerator({
        //   dictionaries: [names],
        //   length: 1
        // });
        if (q.questionID.toLowerCase().includes("first")) randomAnswer = name();
        else if (q.questionID.toLowerCase().includes("last"))
          randomAnswer = surname();
        else randomAnswer = name();
      } else if (q.questionID.toLowerCase() == "gpa")
        randomAnswer = generateRandomNumber(1.0, 4.0);
      else if (q.questionID.toLowerCase() == "city")
        // randomAnswer = generateRandomCity();
        randomAnswer = faker.address.city();
      else if (q.questionID.toLowerCase() == "state")
        randomAnswer = faker.address.stateAbbr();
      else if (q.questionID.toLowerCase() == "major")
        randomAnswer = generateRandomMajor();
      else if (q.questionID.toLowerCase() == "zipcode") {
        randomAnswer = faker.address.zipCode("#####");
      } else if (q.questionID.toLowerCase() == "email") {
        const domains = [
          "@gmail.com",
          "@example.com",
          "@yahoo.com",
          "@umsystem.edu",
          "@soarai.com",
          "@hotmail.com",
        ];
        const numberDictionary = NumberDictionary.generate({
          min: 10,
          max: 9999,
        });
        randomAnswer = uniqueNamesGenerator({
          dictionaries: [[username()], numberDictionary, domains],
          length: 3,
          separator: "",
        });
        randomAnswer = faker.internet.email();
      } else if (q.question_text.toLowerCase().includes("address")) {
        randomAnswer = faker.address.streetAddress();
      } else if (
        q.question_text.toLowerCase().includes("telephone") ||
        q.question_text.toLowerCase().includes("phone number") ||
        q.question_text.toLowerCase().includes("call number")
      ) {
        const numberDictionary = NumberDictionary.generate({ length: 10 });
        const customConfig = {
          dictionaries: [numberDictionary],
        };
        //   console.log(numberDictionary)

        randomAnswer = uniqueNamesGenerator(customConfig);
      } else {
        const customConfig = {
          dictionaries: [adjectives, colors],
          separator: " ",
          length: 2,
          style: "capital",
        };

        randomAnswer = uniqueNamesGenerator(customConfig);
      }
      // console.log(q.questionID, randomAnswer)
      // setValue(q.questionID, randomAnswer)
      randomAnswers[q.questionID] = randomAnswer;
    } else if (q.question_type == "phone_number") {
      let randomAnswer = "";
      const numberDictionary = NumberDictionary.generate({ length: 10 });
      const customConfig = {
        dictionaries: [numberDictionary],
      };
      // console.log(numberDictionary)

      randomAnswer = uniqueNamesGenerator(customConfig);
      // setValue(q.questionID, randomAnswer)
      randomAnswers[q.questionID] = randomAnswer;
    } else if (q.question_type == "email") {
      const domains = [
        "@gmail.com",
        "@example.com",
        "@yahoo.com",
        "@umsystem.edu",
        "@soarai.com",
      ];
      const numberDictionary = NumberDictionary.generate({
        min: 10,
        max: 9999,
      });
      const randomAnswer = uniqueNamesGenerator({
        dictionaries: [[username()], numberDictionary, domains],
        length: 3,
        separator: "",
      }); // DangerousSnake123
      randomAnswers[q.questionID] = randomAnswer;
    } else if (q["question_type"] === "long_answer") {
      // let randomAnswer = loremIpsum(1, 5, 1, false, true)
      // let randomAnswer = loremIpsum({ p: 1, avgSentencesPerParagraph: 3, avgWordsPerSentence: 6 })
      // randomAnswers[q.questionID] = randomAnswer.toString();
      let randomLength = faker.datatype.number({ min: 10, max: 30 });
      let randomAnswer = faker.random.words(randomLength);
      randomAnswers[q.questionID] = randomAnswer.toString();
    } else if (q["question_type"] === "multiple_choice") {
      const randomOptionDictionary = NumberDictionary.generate({
        min: 1,
        max: q.options.length,
      });
      const randomAnswer = uniqueNamesGenerator({ dictionaries: [q.options] }); // DangerousSnake123
      randomAnswers[q.questionID] = randomAnswer;
    } else if (q["question_type"] === "checkbox") {
      // console.log(q)
      const randomNumber = uniqueNamesGenerator({
        dictionaries: [
          NumberDictionary.generate({ min: 0, max: q.options.length }),
        ],
      });
      for (var i = 0; i < randomNumber; i++) {
        const randomProb = uniqueNamesGenerator({
          dictionaries: [NumberDictionary.generate({ min: 0, max: 10 })],
        });
        randomAnswers[q.questionID] = {};

        // const randomNumber = 0
        if (randomProb < 65) {
          // let randomRange = Math.floor(Math.random() * q.options.length);
          const temp = Math.random() * q.options.length;
          // console.log('rantomTemp', temp)
          let randomInd = Math.floor(temp);
          // console.log('randomRange', randomRange)
          // console.log('randomInd', randomInd)

          let randomAnswer = q.options[0];
          if (randomInd < q.options.length) {
            randomAnswer = q.options[randomInd];
          } else {
            randomAnswer = q.options.slice(-1)[0];
          }
          // console.log(randomAnswer)
          if (randomAnswer.toLowerCase() == "other") {
            const randomString = loremIpsum({
              avgSentencesPerParagraph: 1,
              avgWordsPerSentence: 3,
              p: 1,
            }).toString();
            // setValue(q.questionID + '.check' + randomAnswer, true)
            // setValue(q.questionID + '.other' + randomAnswer, randomString)
            randomAnswers[q.questionID][".check" + randomAnswer] = true;
            randomAnswers[q.questionID][".other" + randomAnswer] = randomString;
          } else {
            // setValue(q.questionID + '.' + randomAnswer, randomAnswer)
            randomAnswers[q.questionID][randomAnswer] = randomAnswer;
          }
        } else {
        }
      }
    } else if (q["question_type"] === "date") {
      const yearDictionary = NumberDictionary.generate({
        min: 1900,
        max: 2022,
      });
      const monthDictionary = NumberDictionary.generate({ min: 1, max: 12 });
      const dayDictionary = NumberDictionary.generate({ min: 1, max: 30 });

      const customConfig = {
        dictionaries: [monthDictionary, dayDictionary, yearDictionary],
        separator: "/",
      };
      const randomAnswer = uniqueNamesGenerator(customConfig); // DangerousSnake123
      // console.log(randomAnswer)
      // setValue(q.questionID, parseMultiple(randomAnswer, DateFormats))
      randomAnswers[q.questionID] = parseMultiple(randomAnswer);
    } else if (q["question_type"] === "checkbox_followup") {
      const randomNumber = uniqueNamesGenerator({
        dictionaries: [NumberDictionary.generate({ min: 0, max: 100 })],
      });
      // const randomNumber = 0
      if (randomNumber < 65) {
        // setValue(q.questionID, true)
        randomAnswers[q.questionID] = true;
      } else {
        // setValue(q.questionID, false)
        randomAnswers[q.questionID] = false;
      }
    }
    // else if (q.question_type === 'rating_bubble') {

    // }
    // else if (q.question_type === 'file_upload') {

    // }
  });
  return randomAnswers;
};
export const generateRandomAnswersBulk = async (formID, repeat = 5) => {
  // const batch = writeBatch(db);
  let formQuestions = [];
  let generatedResponsesArray = [];
  if (formID) {
    let docSnapshot = await getDoc(doc(db, "form_library", formID));
    let formData = {};
    if (docSnapshot.data()) {
      formData = docSnapshot.data();
      formQuestions = formData["form_questions"];

      for (let i = 0; i < repeat; i++) {
        let randomAnswers = generateRandomAnswersSilent(formID, formQuestions);
        const randomID = makeid(20) + "-bot";

        randomAnswers = {
          ...randomAnswers,
          formSubmissionID: randomID,
          createdAt: randomDate(),
          userID: `automatic-bot-${Math.floor(Math.random() * 100)}`,
        };
        generatedResponsesArray.push(randomAnswers);
        // break;
      }
    }
  }
  return generatedResponsesArray;
};
export function generateRandomNumber(min, max) {
  let highlightedNumber = Math.random() * (max - min) + min;

  return highlightedNumber.toFixed(2);
}
export const generateRandomCity = () => {
  var randomnumber = Math.floor(Math.random() * (cities.length - 1 + 1)) + 1;
  return cities[randomnumber];
};
export const generateRandomState = () => {
  var randomnumber = Math.floor(Math.random() * (states.length - 1 + 1)) + 1;
  return states[randomnumber].abbreviation;
};
export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
export const generateRandomMajor = () => {
  var randomnumber = getRandomInt(majors.length);

  return majors[randomnumber].name;
};
export function random_rgba() {
  var o = Math.round,
    r = Math.random,
    s = 255;
  var randomAlpha = (Math.random() * (0.6 - 0.8) + 0.4).toFixed(1);
  console.log("randomAlpha", randomAlpha);
  return (
    "rgba(" +
    o(r() * s) +
    "," +
    o(r() * s) +
    "," +
    o(r() * s) +
    "," +
    randomAlpha +
    ")"
  );
}
export function random_rgb() {
  var o = Math.round,
    r = Math.random,
    s = 255;
  return "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + ")";
}
export function getRGB(color) {
  let rgba = color.slice(5, -1); // Remove "rgba(" from start and ")" from end.
  let components = rgba.split(","); // Split into an array ["255", "0", "255", "0.4"].

  // Return the first three components joined with commas.
  return `rgb(${components[0]}, ${components[1]}, ${components[2]})`;
}
export function ConvertRGBtoRGBA(rgb, alpha) {
  let components = rgb.slice(4, -1);
  return `rgba(${components}, ${alpha})`;
}
export function isPositiveInteger(value) {
  console.log("value:", value);
  return /^0$|^[1-9]\d*$/.test(value);
}
export function extractYear(dateString) {
  // Define regular expressions for different date formats
  const regexes = [
    /\b\d{4}\b/, // Matches "YYYY" format
    /\b\d{2}\/\d{2}\/\d{4}\b/, // Matches "MM/DD/YYYY" format
    /\b\d{4}-\d{2}-\d{2}\b/, // Matches "YYYY-MM-DD" format
  ];

  // Iterate through the regexes and find the matching format
  for (const regex of regexes) {
    const match = dateString.match(regex);
    if (match) {
      return match[0];
    }
  }

  // Return null if no matching format is found
  return null;
}
export function isBase64Encoded(str) {
  const base64Regex =
    /^([A-Za-z0-9+/]{4})*(([A-Za-z0-9+/]{2}==)|([A-Za-z0-9+/]{3}=)|([A-Za-z0-9+/]{4}))$/;
  if (base64Regex.test(str)) return true;
  return false;
}
export function getAcronym(text) {
  if (text) {
    // console.log('text', text)
    return text
      .trim()
      .split(" ")
      .map((word) => word[0].toUpperCase())
      .join("");
  }
}
export function countWords(text) {
  return text.trim().split(" ").length;
}
export function processKeywords(text) {
  // console.log('text', text)
  return text
    .trim()
    .replace(/\//g, " ")
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
    .toLowerCase();
}
export function getFirstTwoWords(text) {
  let words = text.trim().split(" ");
  return words.slice(0, 2);
}
export function getInsideParentheses(str) {
  const match = str.match(/\(([^)]+)\)/);
  return match ? match[1] : "";
}
export function checkDateRangeStatus(startDateStr, endDateStr) {
  // Check for null or undefined dates
  if (!startDateStr || !endDateStr) {
    console.log("Invalid date range provided.");
    return;
  }

  // Parse dates
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Get today's date
  const today = new Date();

  // Set the hours, minutes, seconds and milliseconds to 0, since we are only comparing the date part
  today.setHours(0, 0, 0, 0);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  if (today < startDate) {
    console.log("The start date has not yet arrived.");
    return "Not yet open";
  } else if (today > endDate) {
    console.log("The end date has passed.");
    return "Expired";
  } else {
    console.log("Today's date is within the start and end dates");
    return "Open";
  }
}
export function isAccessAllowed(objectMetadata, userData) {
  const {
    status,
    formDomain,
    allowedInstitutions,
    excludedInstitutions,
    allowedRoles,
  } = objectMetadata;

  if (status !== "published") {
    return false;
  }

  const { institution, atype } = userData;
  const compatAtype = equivalentRoleDict[atype]; // Assuming equivalentRoleDict is accessible in this scope
  // console.log('allowedRoles', allowedRoles, 'compatAtype', compatAtype, allowedRoles.includes(compatAtype))
  if (formDomain === "Common" && excludedInstitutions.includes(institution)) {
    return false;
  } else if (
    formDomain === "Specific" &&
    !allowedInstitutions.includes(institution)
  ) {
    return false;
  }

  return allowedRoles.includes(compatAtype);
}
export function sanitizeForCSV(input) {
  input = input.replace(/\"/g, '""');
  return input;
}

//console.log = function () { }
